import React, { useState } from "react";
import { useNavigate } from 'react-router';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failedAuth, setFailedAuth] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState("false")
  const [startRef, setStartRef] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [authMessage, setAuthMessage] = useState("Incorrect email or password!")

if (startRef === 0) {
  setEmailSuccess(localStorage.getItem("confirmationSuccess"));
  setStartRef(1);
}
  localStorage.setItem("loginSuccess", false);
  localStorage.setItem("userEmail", email)

  const formData = {
    userPassword: password,
    userEmail: email
  };

  function handleSubmit(event) {
    event.preventDefault();
    setAuthMessage("Incorrect email or password!")
    setIsLoading(true)
    if (!email || !password) {
      if (!email && !password) {
        setIsLoading(false);
        setFailedAuth(true);
        setAuthMessage("Plese input an email and a password.")
      }
      else if (!email) {
        setIsLoading(false);
        setFailedAuth(true);
        setAuthMessage("Email field empty!");
      }
      else if (!password) {
        setIsLoading(false);
        setFailedAuth(true);
        setAuthMessage("Password field empty!");
      }
  } else {
    console.log(formData);
    fetch("https://chat.smarthost.metalogix.solutions:8881/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let confirm = {
          data: data,
          loggedIn: data.loggedIn,
          token: data.token,
          message: data.message
        };
        console.log(confirm.loggedIn);
        if (data.status !== "success") {
          setFailedAuth(true);
          setIsLoading(false)
          setAuthMessage(confirm.message)
        }
        if (data.status === "success" && confirm.loggedIn === true) {
          localStorage.setItem("userEmail", email)
          localStorage.setItem("loginSuccess", true);
          localStorage.setItem("confirmationSuccess", false)
          localStorage.setItem("token", confirm.token);
          setIsLoading(false)
          navigate('/chat');
        }
        console.log(confirm.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false)
      });
    console.log(failedAuth);
  }
}

  return (
    <>
      <div className="flex flex-wrap flex-col items-center w-full h-full mt-1">
        <img alt="logo" src="https://i.imgur.com/WFszdbk.png" className="loginLogo mt-12 mb-1 "></img>
        <h1 className="tracking-wide pt-5 text-5xl pb-2">SmartHost</h1>
        <p className="underlogo pt-1">The center of your experience.</p>
        <div className="container w-full p-10">
          <form className="flex flex-col justify-center items-center" onSubmit={handleSubmit}>
            {isLoading ? <div className="lds-dual-ring"></div> : failedAuth  ? <div className="rounded-3xl p-1 failedauth pr-3 pl-3 text-center"> {authMessage} </div> 
            : emailSuccess === 'true' && <div className="rounded-3xl p-1 failedauth pr-3 pl-3 text-center"> Email verification successful. </div> 
            }
            <div className="logincontainer flex-col justify-center items-center flex"> 
              <input
                autoFocus
                className="border-b-2 mt-6 outline-none text-center p-2 w-full logininput "
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="border-b-2 m-2 outline-none text-center p-2 w-full logininput"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div>
                <button id="loginButton" className="login-btn mt-7">LOGIN</button>
              </div>
            </div>
          </form>
          <div className="flex flex-row gap-2 pt-6 justify-center">
              <p className="registertext pt-2"> Don't have an account? </p>
              <button onClick={(e) => {navigate("/register")}} className="registertext underline pt-[0.35rem]">Sign Up</button> 
          </div>
        </div>
      </div>
    </>
  );
}