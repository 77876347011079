import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { RegisterCheck }  from './RegisterChecks'

export default function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  localStorage.setItem('confirmationSuccess', false);

  // failed auth states
  const [failedConfirm, setFailedConfirm] = useState(false);
  const [failPlaceHolder, setFailPlaceholder] = useState('');

  localStorage.setItem('userName', name);
  localStorage.setItem('userAddress', address);
  localStorage.setItem('userPassword', password);

  localStorage.setItem('userEmail', email);

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true)

    //short timeout to prevent spam
    console.log('before');
    setTimeout(function(){
    console.log('after');
    
    // Reset error state
    setFailedConfirm(false);
    setFailPlaceholder('');

    if (
      name &&
      address &&
      email &&
      password === confirmPassword &&
      password.length >= 9 &&
      /\d/.test(password) &&
      /[a-zA-Z]/g.test(password) &&
      /[A-Z]/.test(password) &&
      !isLoading
    ) {
      localStorage.setItem('confirmationStart', true);
      navigate('/confirmation');
    } else {
      setFailedConfirm(true);
      setIsLoading(false)
      setFailPlaceholder(RegisterCheck(name, address, email, password, confirmPassword))
    }
    }, 500);
  }

  return (
    <>
      <div className="flex flex-wrap flex-col items-center w-full h-full">
        <img
          alt="logo"
          src="https://i.imgur.com/WFszdbk.png"
          className="loginLogo mt-10 "
        ></img>
        <h1 className="tracking-wide pt-5 text-5xl pb-2">SmartHost</h1>
        <p className="underlogo pt-1">The center of your experience.</p>
        <div className="p-5">
          <form
            className="flex justify-center items-center"
            onSubmit={handleSubmit}
          >
            <div className="logincontainer flex-col justify-center items-center flex ">
            {isLoading ? <div className="lds-dual-ring"></div> : failedConfirm  && <div className="rounded-3xl p-1 failedauth pr-3 pl-3 text-center"> {failPlaceHolder} </div>}
              <input
                autoFocus
                className={
                     'border-b-2 outline-none text-center p-2 w-full logininput'
                }
                type="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <input
                type="address"
                className={
                     'border-b-2 outline-none text-center p-2 w-full logininput'
                }
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <input
                type="email"
                className={
                    'border-b-2 outline-none text-center p-2 w-full logininput'
                }
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                type="password"
                className={
                   'border-b-2 outline-none text-center p-2 w-full logininput'
                }
                placeholder={
                  'Password' 
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <input
                type="password"
                className={
                     'border-b-2 mb-4 outline-none text-center p-2 w-full logininput'
                }
                placeholder={
                  'Confirm Password'
                }
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div>
                <button id="registerButton" className="register-btn mt-1">
                  REGISTER
                </button>
              </div>
            </div>
          </form>
          <div className="flex flex-row gap-1 justify-center pt-2">
            <p className="registertext pt-[0.78rem]">Or</p>
            <button onClick={(e) => {navigate("/")}} className="registertext underline pt-[0.55rem]">Login</button>
            <p className="registertext pt-[0.78rem]">instead</p>
          </div>
        </div>
      </div>
    </>
  );
}