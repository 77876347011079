import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

export default function Confirmation() {
  const navigate = useNavigate();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const loginSuccess = localStorage.getItem('loginSuccess');
  localStorage.setItem('confirmationSuccess', emailSuccess);
  localStorage.setItem('confirmationToken', confirmation.token)

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const userAddress = localStorage.getItem("userAddress");
    const userPassword = localStorage.getItem("userPassword");
    const userEmail = localStorage.getItem("userEmail");

    const formData = {
      userName: userName,
      userPassword: userPassword,
      userAddress: userAddress,
      userEmail: userEmail,
    };

    console.log(formData)

    if (!confirmation) {
      fetch("https://chat.smarthost.metalogix.solutions:8881/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let confirm = {
            status: data['status'],
            token: data['token']
          };
          console.log(confirm.token);
          console.log(confirm.status);
          setConfirmation(confirm);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    const interval = setInterval(() => {
      fetch(`https://chat.smarthost.metalogix.solutions:8881/api/verify?token=${confirmation.token}&check=True`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setEmailSuccess(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 5000);
    
    return () => clearInterval(interval);
  }, [confirmation]);

  useEffect(() => {
    if (emailSuccess === true) {
      localStorage.setItem("emailSuccess", emailSuccess);
      navigate("/");
    }
  }, [emailSuccess, navigate]);

  useEffect(() => {
    if (loginSuccess === 'true' || emailSuccess === 'true') {
      navigate("/");
    }
  }, [loginSuccess, emailSuccess, navigate]);

  return (
    <>
      <div className="flex flex-wrap flex-col items-center w-full h-full">
        <img alt="logo" src="https://i.imgur.com/WFszdbk.png" className="loginLogo mt-12 "></img>
        <h1 className="tracking-wide pt-5 text-5xl pb-2">SmartHost</h1>
        <p className="underlogo pt-1">The center of your experience.</p>
        <div className="emailverify rounded-3xl p-3 mt-10">
          <p>Please check your email.</p>
        </div>
      </div>
    </>
  );
}