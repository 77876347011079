export function AddTime() {
    const currentDate = new Date();
    let datetime = '';
    if (currentDate.getMinutes() < 10) {
      datetime =
        currentDate.getHours() +
        ':' +
        '0' +
        currentDate.getMinutes();
    } else {
      datetime =
        currentDate.getHours() +
        ':' +
        currentDate.getMinutes();
    }
    return datetime;
  }
