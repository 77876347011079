import React, { useEffect, useState } from "react";

const Carousal = ({ arg }) => {
  const [mediaQuery, setMediaQuery] = useState('');
  console.log(mediaQuery)

  

  useEffect(() => {
    const handleResize = () => {
      const mqM= window.matchMedia('(min-width: 1200px)'); 
      const mqL= window.matchMedia('(max-width: 1024px)')
      const mqD = window.matchMedia('(min-width: 1024px)')

      if (mqM.matches) {
        setMediaQuery('mobile');
      } else if (mqL.matches) {
        setMediaQuery('laptop');
      } else if (mqD.matches) {
        setMediaQuery('desktop')
      }
    };

    handleResize(); // Call the function initially

    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  

  useEffect(() => {
    
    const track = document.querySelector('.carousel-track');
    const reviews = Array.from(track.children);
    const nextReview = document.querySelector('.menu-next');
    const prevReview = document.querySelector('.menu-prev');
    const trackSizeWidth = reviews[0].getBoundingClientRect().width;

    const setReviewPosition = (review, index) => {
      review.style.left = trackSizeWidth * index + 'px';
    }

    reviews.forEach(setReviewPosition);

    const moveToReview = (track, currentReview, targetReview) => {
      track.style.transform = 'translateX(-' + targetReview.style.left + ')';
      currentReview.classList.remove('current-review');
      targetReview.classList.add('current-review');
    }

    const toggleArrows = (reviews, targetIndex) => {
      const nextReview = document.querySelector('.menu-next');
      const prevReview = document.querySelector('.menu-prev');
      const mediaL = window.matchMedia('(min-width: 1200px)')
      const mediaM = window.matchMedia('(max-width: 1300px)')
      const mediaMS = window.matchMedia('(max-width: 1025px)')
      const mediaS = window.matchMedia('(max-width: 676px)')

    if (mediaL.matches)
   { 
        prevReview.classList.add('hidden');
        nextReview.classList.add('hidden');
        if (reviews.length <= 4) {
        prevReview.classList.add('hidden');
        nextReview.classList.add('hidden');
      }  else if (targetIndex === 0) {
        prevReview.classList.add('hidden');
        nextReview.classList.remove('hidden');
      } else if (targetIndex === reviews.length - 4) {
        prevReview.classList.remove('hidden');
        nextReview.classList.add('hidden');
      } else {
        prevReview.classList.remove('hidden');
        nextReview.classList.remove('hidden');
      }
    }

    if (mediaM.matches)
  { 
  if (reviews.length <= 3) {
    console.log('removing')
    prevReview.classList.add('hidden');
    nextReview.classList.add('hidden');
  } else if (targetIndex === 0) {
    prevReview.classList.add('hidden');
    nextReview.classList.remove('hidden');
  } else if (targetIndex === reviews.length - 3) {
    prevReview.classList.remove('hidden');
    nextReview.classList.add('hidden');
  } else {
    prevReview.classList.remove('hidden');
    nextReview.classList.remove('hidden');
  }
  }

  if (mediaMS.matches)
  { 
  if (reviews.length <= 2) {
    console.log('removing')
    prevReview.classList.add('hidden');
    nextReview.classList.add('hidden');
  } else if (targetIndex === 0) {
    prevReview.classList.add('hidden');
    nextReview.classList.remove('hidden');
  } else if (targetIndex === reviews.length - 2) {
    prevReview.classList.remove('hidden');
    nextReview.classList.add('hidden');
  } else {
    prevReview.classList.remove('hidden');
    nextReview.classList.remove('hidden');
  }
  }

    if (mediaS.matches)
  { 
  if (reviews.length <= 1) {
    console.log('removing')
    prevReview.classList.add('hidden');
    nextReview.classList.add('hidden');
  } else if (targetIndex === 0) {
    prevReview.classList.add('hidden');
    nextReview.classList.remove('hidden');
  } else if (targetIndex === reviews.length - 1) {
    prevReview.classList.remove('hidden');
    nextReview.classList.add('hidden');
  } else {
    prevReview.classList.remove('hidden');
    nextReview.classList.remove('hidden');
  }
  }
    }

    const interval = setInterval(() => {
      const currentReview = track.querySelector('.current-review');
      const currentIndex = reviews.findIndex(review => review === currentReview);
      toggleArrows(reviews, currentIndex)}, 100)

    // When I click left, move reviews to the left
    
    prevReview.addEventListener('click', e => {
      const currentReview = track.querySelector('.current-review');
      console.log(currentReview)
      const prevReview = currentReview.previousElementSibling;
      const prevIndex = reviews.findIndex(review => review === prevReview);

      moveToReview(track, currentReview, prevReview);
      toggleArrows(reviews, prevIndex);
    });

    // When I click right, move reviews to the right
    nextReview.addEventListener('click', e => {
      const currentReview = track.querySelector('.current-review');
      const nextReview = currentReview.nextElementSibling;
      const nextIndex = reviews.findIndex(review => review === nextReview);

      moveToReview(track, currentReview, nextReview);
      toggleArrows(reviews, nextIndex);
    });
    return () => clearInterval(interval);
  }, []);

  const renderCarousal = (message) => {
    console.log(message)
    return (
        <div dangerouslySetInnerHTML={{ __html: arg.text }}></div> 
      )
  }

  return (
    <>
    {renderCarousal(arg)}
    </>
  );
};

export default Carousal;