import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Loading from './Loading';
import { useNavigate } from 'react-router';
import { AddTime } from './AddTime';
import { MarkdownRenderer } from './MarkdownRenderer';

const ChatbotUI = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [startRef, setStartRef] = useState(0);
  const [botMetadata, setBotMetadata] = useState("")
  const [characterCount, setCharacterCount] = useState(0);
  const [characterLimitReached, setCharacterLimitReached] = useState(false);
  const [currentMessage, setCurrentMessage] = useState([]);

  let currentWordIndex = 0

  const textareaRef = useRef(null);

  const userEmail = localStorage.getItem('userEmail');
  const loginSuccess = localStorage.getItem('loginSuccess');
  const token = localStorage.getItem('token');
  const chatboxRef = useRef(null);
  const maxCharacters = 350;

  //adds event listeners to markdown buttons

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoading && textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 100);

    return () => clearInterval(interval); // Clear the interval on component unmount

  }, [isLoading]);

  useEffect(() => {
    const handleButtonClick = (event) => {
      const { target } = event;

      if (target.matches('#correct') || target.matches('#incorrect')) {
        const value = target.value;
        if (value) {
          forceFetch(value)
          const buttonsToRemove = document.querySelectorAll('#correct, #incorrect');
          buttonsToRemove.forEach((button) => {
            button.setAttribute('disabled', '');
          });
        }
      }
    };

    document.addEventListener('click', handleButtonClick);

    // clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  },);

  //checks if authorization has already occurred
  useEffect(() => {
    const storedStartIndex = localStorage.getItem('startIndex');
    if (storedStartIndex !== null) {
      setStartIndex(parseInt(storedStartIndex));
    }
  }, []);

  //navigate if unauthorized
  useEffect(() => {
    if (loginSuccess === 'false') {
      navigate('/');
    }
  }, [loginSuccess, navigate]);


  //authorize conversation
  useEffect(() => {
    if (startIndex === 0) {
      setIsLoading(true);
      fetch('https://chat.smarthost.metalogix.solutions:8881/api/initConversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {

          const words = data.response.split(' '); // Split the message into words
          setCurrentMessage(words);
                   
          //set welcome response
          let botMessage = {
            text: "",
            metadata: 'host',
          };
          console.log(data);

          if (data.state !== 'success') {
            botMessage = {
              text: 'ERROR! Backend response failed.',
              sender: 'bot',
              metadata: 'fail'
            };
          }
          //add welcome response to messages arary
          setMessages((prevMessages) => [...prevMessages, botMessage]);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          const errorMessage = {
            text: 'Chatbot timed out',
            sender: 'bot',
            metadata: 'fail'
          };
          setMessages((prevMessages) => [...prevMessages, errorMessage]);
          setIsLoading(false);
        });
      setStartIndex(1);
    }
  }, [startIndex, token, userEmail]);

  useEffect(() => {
    if (currentMessage.length > 0) {
      displayWordsOneAtATime();
    }
  }, [currentMessage]);

  const displayWordsOneAtATime = () => {
    if (currentWordIndex < currentMessage.length) {
      const word = currentMessage[currentWordIndex];
      const botMessageIndex = messages.findIndex((message) => message.metadata === 'host');
      
      if (botMessageIndex !== -1) {
        // Append the current word to the existing bot message text
        messages[botMessageIndex].text += ' ' + word;
        setMessages([...messages]); // Update the messages state
      }
      
      currentWordIndex++
  
      // Delay before displaying the next word (adjust the delay time as needed)
      setTimeout(displayWordsOneAtATime, 200); // 1000ms = 1 second
    }
  };


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (inputValue.trim() !== '') {
        handleFormSubmit(e); // Call the form submit handler when Enter is pressed
      }
    } else {
      if (inputValue.length <= maxCharacters) {
        setInputText(inputValue);
        setCharacterCount(inputValue.length);
      }
      const textarea = e.target;
      textarea.style.height = 'auto'; // Reset height to get accurate scroll height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scroll height

      if (inputValue.length >= maxCharacters) {
        setCharacterLimitReached(true);
      } else {
        setCharacterLimitReached(false);
      }
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setCharacterCount(0)

    // set button values to incorrect
    const buttonsToRemove = document.querySelectorAll('#correct, #incorrect');
    buttonsToRemove.forEach((button) => {
      button.setAttribute('disabled', '');
    });

    //handle user input
    if (inputText.trim() !== '') {
      const userMessage = {
        text: inputText,
        sender: 'user',
        date: AddTime(),
        metadata: 'null',
      };

      if (/[@#%^&*(){}|<>]/.test(inputText)) {
        setIsLoading(true)
        const errorMessage = {
          text: 'Input contains special characters. Please try again.',
          sender: 'bot',
          metadata: 'fail'
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        setInputText('')
        setIsLoading(false)
        return;
      }
      //add user message to messages array
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      //conversation api post
      if (startIndex !== 0) {
        console.log('FETCHING')
        setBotMetadata('bot')
        setIsLoading(true);
        fetch('https://chat.smarthost.metalogix.solutions:8881/api/startConversation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: token,
          },
          body: new URLSearchParams({
            text: inputText,
            confirm: "False"
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            //set bot response
            let botMessage = {
              text: data.response,
              sender: 'bot',
              services: data.services,
              menu: data.menu === "True" ? "True" : "False"
            };
            console.log(data)
            console.log(data.menu)
            if (data.state !== 'success') {
              botMessage = {
                text: 'ERROR! Backend response failed.',
                sender: 'bot',
                metadata: 'fail'
              };
            }
            //add bot message to messages array
            setMessages((prevMessages) => [...prevMessages, botMessage]);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
          });

        setInputText('');

      }
    }

  };

  useEffect(() => {
    if (messages.some((message) => message.metadata === 'host')) {
      setStartRef(1);
    }
  }, [messages]);

  //called when confirm value becomes correct
  function forceFetch(arg) {
    if (startIndex !== 0) {
      console.log('FETCHINGTRUE')
      setBotMetadata('confirmed')
      setIsLoading(true);
      fetch('https://chat.smarthost.metalogix.solutions:8881/api/startConversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: token,
        },
        body: new URLSearchParams({
          text: arg,
          confirm: 'True'
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let botMessage = {
            text: data.response,
            sender: 'bot',
            services: data.services,
            metadata: 'confirmed',
            menu: data.menu
          };
          console.log(data)
          console.log(data.response);
          console.log(data.menu)
          if (data.state !== 'success') {
            botMessage = {
              text: 'ERROR! Backend response failed.',
              sender: 'bot',
              metadata: 'fail'
            };
          }

          setMessages((prevMessages) => [...prevMessages, botMessage]);
          setIsLoading(false);
          console.log(botMetadata)


        })
        .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
        });


      setInputText('');
    }
  }

  useEffect(() => {
    const scrollToEnd = () => {
      window.scrollTo(0, document.body.scrollHeight);
    };

    if (isLoading) {
      // Scroll to the bottom when loading starts
      scrollToEnd();
    } else {
      // Scroll to the "fail" element when loading ends
      const failMessageIndex = messages.findIndex((message) => message.metadata === 'fail');
      if (failMessageIndex !== -1) {
        setTimeout(() => {
          console.log('SUCCEED')
          scrollToEnd()
        }, 0);
      } else {
        // Scroll to the bottom when loading ends
        scrollToEnd();
      }
    }
  }, [isLoading, messages]);

  useEffect(() => {
    if (!isLoading) {
      // Force an update of the textarea rows when loading state changes
      const textarea = document.querySelector('.textarea1');
      if (textarea) {
        textarea.rows = 1;
      }
    }
  }, [isLoading]);

  return (
    <>
      <div className="actual">
        <img src="https://i.imgur.com/8mxpCZ8.png" alt="logo" className="logo absolute"></img>
      </div>
      <div className="flex flex-col m-10 center-items">

        <div id="overlay" className="chatbox container w-full mx-auto bg-white rounded-xl m-4 scroll-smooth pt-20 mt-[6.5rem]" ref={chatboxRef}>
          <div className="logout">
            <button onClick={(e) => { navigate("/") }} id="registerButton" className="register-btnone w-[5rem] relative bottom-[10rem]">Log out</button>
          </div>
          <TransitionGroup>
            {messages.map((message, index) => (
              <CSSTransition key={index} timeout={300} classNames="fade">
                <>
                  {message.menu !== "True" && <div
                    className={`message ${message.sender === 'user' ? 'user flex flex-row items-start' : 'bot'}`}
                  >
                    <p className="datetime mt-[2.28rem] ml-8 text-lg text-center">{message.date}</p>
                    <div
                      className={`message ${message.metadata === 'fail'
                          ? 'fail-chat border-solid border-2 w-auto min-w-[18em]'
                          : message.metadata === 'host'
                            ? 'host-chat border-solid border-2 w-auto min-w-[18em]'
                            : message.sender === 'bot'
                              ? 'bot-chat border-solid border-2 w-auto min-w-[18em]'
                              : 'user-chat w-auto border-solid'
                        } p-2 break-words mt-5`}
                    >

                      <div className="flex flex-col items-center justify-start md:flex-row">
                        {message.sender === 'bot' && (
                          <p
                            className={
                              message.metadata === 'fail'
                                ? 'border-2 rounded-2xl border-white fail bg-white p-1 ml-5 h-full w-[5rem] text-center'
                                : message.metadata === 'host'
                                  ? 'border-2 rounded-2xl border-white host bg-white pt-1 pb-1 pl-2 pr-2 ml-5 h-full w-[4-rem] text-center'
                                  : 'border-2 rounded-2xl border-white smarthost text-lg bg-white pt-1 pb-1 pl-2 pr-2 ml-5 text-center'
                            }
                          >
                            {message.metadata === 'fail' ? 'ERROR'
                              : message.metadata === 'host' ? 'HOST' : 'SMARTHOST'}
                          </p>

                        )}
                        <div className="">
                          <div
                            className={
                              message.sender === 'user'
                                ? 'p-2 tracking-wide leading-5 text-lg user-text top-[0.1rem] md:leading-7 mt-[0rem] text-left md-tracking-wider md:text-xl md:pr-0'
                                : `w-full pt-2 pb-2 tracking-wide leading-7 text-xl text-white ml-3 text-center pr-8 md:leading-9 md-tracking-wider md:text-left md:text-2xl`}
                          >
                            {message.menu !== "True"
                              && message.sender === 'user'
                              ? message.text
                              : message.metadata === 'bot'
                                ? message.text
                                : MarkdownRenderer(message.text)
                            }


                          </div>

                        </div>
                      </div>
                    </div>

                  </div>}
                </>
              </CSSTransition>
            ))}
          </TransitionGroup>

          {isLoading && <Loading arg={botMetadata === 'confirmed' ? 'confirmed' : startRef === 0 ? 'host' : 'bot'} />}
          <form className="pt-4 pb-4 pl-[-1rem]" onSubmit={handleFormSubmit}>
            <div className="flex-grow flex flex-row justify-between items-end px-4 rounded-2xl border-2 inputarea py-2 min-w-[18rem] lg:h-full forminput">
              <textarea
                ref={textareaRef}
                type="text"
                value={inputText}
                onChange={handleInputChange}
                onKeyDown={handleInputChange}
                placeholder={isLoading ? 'Loading, please wait...' : 'Type your message...'}
                className="outline-none w-full bg-white break-word textarea1"
                disabled={isLoading}
                rows={isLoading ? 1 : 1}
                maxLength={maxCharacters}
              />

              <input
                type="image"
                name="submit"
                value="submit"
                src="https://i.imgur.com/EfeKh2j.png"
                className="sendButton ml-2"
                disabled={startRef === 0}
                alt="button"
              />
            </div>
            <div className="">
              <p className={characterLimitReached ? 'text-red-500 mt-2 underlogo maxchar mr-[1em]' : ' mt-2 underlogo maxchar mr-[1rem]'}>{characterCount}/{maxCharacters}</p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatbotUI