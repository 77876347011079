function RegisterCheck(name, address, email, password, confirmPassword) {
    const conditions = {
        "con1": 'Name is required!',
        "con2": 'Address is required!',
        "con3": 'Email is required!',
        "con4": 'Password is required!',
        "con5": 'Passwords do not match!',
        "con6": 'Password is too short!',
        "con7": 'Password must contain at least one number!',
        "con8": 'Password must contain at least one letter!',
        "con9": 'Password must contain at least one uppercase letter!'
    } 

    if (!name) {
    return conditions["con1"]
    } else if (!address) {
        return conditions["con2"]
    } else if (!email) {
        return conditions["con3"]
    } else if (!password) {
        return conditions["con4"]
    } else if (password !== confirmPassword) {
        return conditions["con5"]
    } else if (password.length < 9) {
        return conditions["con6"]
    } else if (!/\d/.test(password)) {
        return conditions["con7"]
    } else if (!/[a-zA-Z]/g.test(password)) {
        return conditions["con8"]
    } else if (!/[A-Z]/.test(password)) {
        return conditions["con9"]
    }
}

export { RegisterCheck }