import React from 'react';
import ChatbotUI from './components/ChatbotUI'
import ChatbotUIHotelKempinski from './components/ChatbotUIHotelKempinski'
import ChatbotUIHotelTheNorman from './components/ChatbotUIHotelTheNorman'
import { Routes, Route } from "react-router-dom";
import Login from './components/Login';
import LoginHotelKempinski from './components/LoginHotelKempinski';
import LoginHotelTheNorman from './components/LoginHotelTheNorman';
import Register from './components/Register';
import Confirmation from './components/Confirmation';


const App = () => {
  return (
    <Routes>
      <Route path ="/" element={<Login />} />
      <Route path ="/hotel/Kempinski" element={<LoginHotelKempinski />} /> 
      <Route path ="/hotel/TheNorman" element={<LoginHotelTheNorman />} /> 
      <Route path ="/confirmation" element={<Confirmation />} /> 
      <Route path="/register" element={<Register/>}/>
      <Route path="/chat" element={<ChatbotUI/>}/>
      <Route path="/hotel/Kempinski/chat" element={<ChatbotUIHotelKempinski/>}/>
      <Route path="/hotel/TheNorman/chat" element={<ChatbotUIHotelTheNorman/>}/>
    </Routes>
  );
};

export default App;